.onboarding-sec {
    background-color: #FAFAFA;
}

.onboarding-header-card h4 {
    font-size: 0.8em;
    font-weight: 700;
    color: var(--teritary-color);
    margin-bottom: 0;
}

.onboarding-header-card {
    display: flex;
    align-items: center;
    gap: 1em;
    /* left: 14%; */
    position: relative;
    justify-content: space-between;
}

.onboarding-box {
    min-height: calc(100vh - 68px);
}

.onboarding-header-sec {
    background-color: var(--secondary-color);
    padding: 1em;
}

.onboarding-link-sec {
    display: flex;
}

.onboarding-left-sec {
    width: 40%;
    position: fixed;
    left: 15%;
    right: 0;
    z-index: 3;
}

.onboarding-left-sec .onboarding-left-img {
    width: 467px;
    position: relative;
}

.onbaording-right-sec {
    width: 40%;
    margin-left: 38em;
    padding-top: 4em;
    position: relative;
}

.onboarding-right-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #45454512;
    border-radius: 0px 20px 20px 0;
    opacity: 1;
    padding: 4em;
    min-height: calc(100vh - 200px);
    position: relative;
}

.create-link-content {
    max-width: 100%;
}

.create-link-sec.sign-in-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 320px);
}

.forgot-password-content.create-link-content h4 {
    margin-bottom: 1em;
}

.step-1 {
    position: relative;
    z-index: 9;
}

.step-2 {
    position: relative;
    z-index: 9;
}

.step-3 {
    position: relative;
    z-index: 9;
}

.step-4 {
    position: relative;
    z-index: 9;
}

.step-5 {
    position: relative;
    z-index: 9;
}

.step-6 {
    position: relative;
    z-index: 9;
}

.step-7 {
    position: relative;
    z-index: 9;
}

.category-list ul li {
    list-style: none;
    cursor: pointer;

}

.category-list ul {
    display: flex;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
}

.category-list ul li.active {
    background-color: var(--quaternary-color) !important;
    color: var(--teritary-color) !important;
}

.category-list ul li span img {
    max-width: 0.8em;
    margin-right: 0.5em;
}


.create-link-sec .additional-education-input input {
    height: 2.8em;
    border: 1px solid #797878;
    border-radius: 50px;
}

.create-link-sec .additional-education-input input:focus {
    box-shadow: none;
    border: 1px solid #797878;
}

.invite-link-title {
    margin: 2em 0;
}

.register-agree-sec {
    padding: 0 0 1em;
}

.register-agree-sec p a {
    font-size: 1em;
    text-decoration: underline !important;

}

.autentication-box .header-back-btn {
    margin: 0 0 0 2em;
}

.category-outline-btn {
    padding: 0.5em 1em;
    border-radius: 50px;
    font-size: 0.8em;
    border: 1px solid #D9D9D9 !important;
    color: var(--teritary-color) !important;
    font-weight: 700;
}

.create-link-input .input-group {
    flex-wrap: nowrap;
}

.create-link-input .input-group-icon {
    font-size: 0.9em;
}

.create-link-input .username-field {
    font-size: 0.9em;
}

.error-content img {
    max-width: 100%;
}

.additional-date-picker .react-date-picker {
    width: 100%;
    height: 50px !important;
    font-size: 15px !important;
    padding: 10px 12px;
}

.additional-date-picker {
    border: 1px solid var(--quinary-color);
    border-radius: 50px;
    height: 50px !important;
    font-size: 18px !important;
}

.additional-date-picker .react-date-picker .react-date-picker__wrapper {
    border: 0;
}

.profile-img {
    position: relative;
    text-align: center;
}

.input-edit-img {
    position: absolute;
    top: 0;
    right: 140px;
    cursor: pointer;
}

.profile-img label {
    cursor: pointer;
}

.profile-image {
    border-radius: 50%;
    width: 8em;
    height: 8em;
    object-fit: cover;
}

.input-edit-img img {
    max-width: 2em;
}

.image-input {
    display: none !important;
}

.profile-image {
    border-radius: 50% !important;
    /* border: 2px solid var(--primary-color) !important; */
    background-color: #45454521;
}

.profile-name-link span img {
    max-width: 2em;
}

.profile-name-link button img {
    max-width: 2em;
}

.profile-name-link span {
    color: var(--teritary-color);
}

.profile-name-link button {
    background-color: transparent;
    padding: 0;
    border-color: transparent !important;
    line-height: 0;
}

.profile-name-link button:hover {
    background-color: transparent !important;
}

.profile-name-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    background: #00C75E27 0% 0% no-repeat padding-box;
    border: 1px solid #00C75E;
    padding: 0.5em 1em;
    border-radius: 50px;
}

.link-account-sec p {
    color: var(--quaternary-color);
    text-align: center;
    margin: 0.5em 0;
    font-size: 1.3em;
    font-weight: 500;
}

.invite-btn {
    background-color: transparent !important;
    border: 1px solid var(--primary-color) !important;
    padding: 0.5em 1.5em !important;
    text-align: center;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 50px !important;
    color: var(--teritary-color) !important;
    display: inline-block !important;
    font-size: 1em !important;
    letter-spacing: 0.8px;
    text-decoration: none !important;
    font-weight: 600 !important;
    width: 100%;
}

.invite-btn:hover {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--secondary-color) !important;
}

.onboard-btn-sec .onboard-btn {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    padding: 0.5em 1.5em !important;
    text-align: center;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 50px !important;
    color: var(--secondary-color) !important;
    display: inline-block !important;
    font-size: 1em !important;
    letter-spacing: 0.8px;
    text-decoration: none !important;
    font-weight: 600 !important;
    width: 100%;
}

.link-account-sec p {
    color: var(--teritary-color);
    text-align: center;
    margin: 0.5em 0;
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

.home-page-link a {
    color: #0C0C0C;
    font-weight: 600;
}

.onboarding-img-logo-sec {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 68px);
    width: 33%;

}

.onboarding-img-logo {
    width: 6em;
}

.header-back-btn button img {
    max-width: 1.5em;
}

button.back-btn.btn.btn-primary {
    background: none;
    border: none;
    position: absolute;
    left: 0px;
    top: 25px;
}

.onboarding-header-card img {
    width: 2em;
    height: 2em;
    border-radius: 50%;
}

.additional-date-picker .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.additional-date-picker  fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}

.profile-logo {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.profile-menu {
    display: flex;
    align-items: center;
    gap: 1em;
}

.profile-menu a {
    color: black;
}

.onboarding-header-sec .navbar{
    width: 100%;
}

.onboarding-header-sec .navbar-nav{
    gap: 1em;
    align-items: center;
}

.btn-header a{
    display: flex;
    align-items: center;
    gap: 1em;
    color: #797878;
    font-size: 0.95em !important;
    /* font-weight: 700; */
    font-weight: 700;
    text-decoration: none!important;
    letter-spacing: 0.8px;
}

.btn-header{
    border: 1px solid var(--primary-color);
    border-radius: 50px!important;
    padding: 0.8em 1.5em!important;
}

.navbar-toggler:focus{
    box-shadow: none!important;
    outline: none!important;
}