.account-sec{
    padding: 2em 0;
}

.account-back-btn-sec .account-back-btn{
    background-color: transparent!important;
    color: var(--tertiary-color);
    display: flex;
    align-items: center;
    gap: 0.2em;
    border: 0!important;
    font-size: 1em;
    font-weight: 700;
    padding: 0;
}

.account-box{
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #45454512;
    padding: 2em;
    margin: 2em 0;
    grid-template-columns: 200px auto;
    display: grid;
}

.account-user-details h4{
    font-weight: 800;
    font-size: 1em;
}

.account-user-img{
    width: 5em;
    height: 5em;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1em;
}
.account-sidebar-list{
    padding:2em 0;
}

.account-sidebar-list li a{
    font-weight: 800;
    font-size: 0.9em;
    color: var(--tertiary-color);
    text-decoration: none;
    display: flex;
    align-items: center;
}

.account-sidebar-list li a.active{
    position: relative;
}


.account-sidebar-list li a.active::before{
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    border: 8px solid var(--primary-color);
    border-width: 0 0 0 8px;
    border-radius: 10px;
}

.account-sidebar-list li a span{
    padding-left: 1.4em;
}

.account-tab-sec .nav-link {
    padding: 0em;
    text-decoration: none;
    color: #96B0B7;
    border-bottom: 5px solid transparent;
    border-radius: 0;
    font-size: 1em;
    font-weight: 700;
    padding-bottom: 0.5em;
}

.account-tab-sec .nav-pills{
    gap: 1em;
}

.account-tab-sec .nav-link.active{
    border-bottom: 5px solid var(--primary-color);
    background-color: transparent;
    color: var(--tertiary-color);
    border-radius: 4px;
}

.account-tab-content-title-sec h4 {
    font-size: 1em;
    font-weight: 700;
    display: inline-block;
}

.account-tab-content-title-sec p {
    font-size: 0.9em;
    color: #454545;
    font-weight: 600;
}

.account-tab-content{
    padding: 2em 0;
}

.link-your-account-sec input {
    height: 2.8em;
    border: 1px solid #797878;
    border-radius: 50px;
    border-left: 0;
}

.link-your-account-sec input:focus {
    box-shadow: none;
    border: 1px solid #797878;
}

.link-your-account-sec .input-group-text{
    height: 2.8em;
    border: 1px solid #797878;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background: transparent;
}

.link-your-account-sec .input-group-text img{
    max-width: 2em;
    width: 1.5em;
}

.link-your-account-sec label{
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.link-your-account-sec .default-btn{
    display: inline-block!important;
    width: unset;
    padding: 0.5em 5em!important;
}

.link-your-account-sec .login-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-sidebar-list li{
    padding-bottom: 1em;
}

.profile-view-img{
    width: 7em;
    height: 7em;
    border-radius: 0;
    object-fit: contain;
    border: 1px dashed #797878;
}

.profile-view-img-sec{
    text-align: center;
    position: relative;
}

.link-your-account-profile-sec input {
    height: 2.8em;
    border: 1px solid #797878;
    border-radius: 50px;
}

.link-your-account-profile-sec input:focus {
    box-shadow: none;
    border: 1px solid #797878;
}

.link-your-account-profile-sec label{
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.link-your-account-profile-sec .default-btn{
    display: inline-block!important;
    width: unset;
    padding: 0.5em 5em!important;
}

.link-your-account-profile-sec .login-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Single Account CSS */

.vertical-center-relative{
    position: relative;
   display: flex;
   flex-direction: column;
   min-height: 90vh;
   justify-content: center;
}


.single-account-box{
    border: 1px solid #D9D9D9;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #45454512;
    border-radius: 24px;
}

.single-account-header-sec{
    padding: 2em;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
    min-height: 12em;
}

.single-account-header-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-profile-link-sec{
    background-color: var(--secondary-color);
    padding: 1em 2em;
    border-radius: 24px;
}

.single-profile-link-sec a{
    gap: 0.5em;
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
    color: #797878;
}

.single-account-header-sec .dropdown-toggle::after{
    display: none;
}

.single-account-header-sec .dropdown-toggle{
    background-color: transparent!important;
    color: var(--secondary-color);
    border: 0!important;
}

.single-account-dashboard-btn{
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 3em;
    height: 3em;
    background-color: var(--secondary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-profile-user-img{
    width: 6em;
    height: 6em;
    object-fit: cover;
    border-radius: 50%;
}

.single-profile-user-details{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    margin-top: -4em;
    z-index: 9;
    position: relative;
    border-bottom:1px solid #EDEDED;
    padding-bottom: 1em;
}

.single-profile-user-details h4{
    font-weight: 800;
    font-size: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.single-profile-total-count-info-box{
    grid-template-columns: repeat(3,1fr);
    display: grid;
    place-items: center;
    text-align: center;
    align-items: center;
    width: 100%;    
}

.single-profile-total-count-card{
    position: relative;
    padding: 1em;
}

.single-profile-total-count-card:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    border: 1px solid rgba(0,0,0,.1);
    top: 0;
    right: -65px;
}

.single-profile-total-count-card:last-child:before{
    display: none;
}

.single-profile-total-count-card h5 {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0;
    color: var(--tertiary-color);
}

.total-audience-reach-badge{
    background-color: #275878;
    padding: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--secondary-color);
    font-weight: 700;
}

.single-profile-post-count-list{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1em;
    font-weight: 700;
    color: var(--tertiary-color);
    margin-bottom: 0;
    padding: 1em 0;
    border-bottom:1px solid #EDEDED;
}

.single-profile-post-count-list li{
    display: flex;
    align-items: center;
    gap: 1em;
}

.single-profile-social-media-follower-icon{
    width: 2em;
}

.single-profile-social-media-followers-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1em 0;
}

.single-profile-social-media-followers-sec span{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.single-profile-social-media-followers-insta{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;
}

.single-profile-social-media-followers-tiktok{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;
}

.profile-dropdown-btn, .profile-dropdown-btn:hover {
    color: var(--tertiary-color);
}

.single-account-header-card .dropdown-item{
    padding: 0!important;
}

.single-account-header-card .dropdown-item .profile-dropdown-btn{
    display: flex;
    align-items: center;
    gap: 0.8em;
    color: #797878;
    padding: 0.8em 1em!important;
    font-size: 1em;
    font-weight: 700;
    text-decoration: none!important;
}

.single-account-header-card .dropdown-menu{
    padding: 0!important;
}

.profile-view-img-sec{
    margin-bottom: 2em;
}

.account-profile-img-upload-sec input[type="file"] {
    display: none;
}

.account-profile-img-upload-sec{
    position: absolute;
    top: 0;
    top: 77%;
    left: 58%;
    border-radius: 50%;
    background: var(--secondary-color);
    z-index: 99;
}

.account-choose-category-sec .default-btn {
    display: inline-block!important;
    width: unset;
    padding: 0.5em 5em!important;
}

.account-choose-category-sec .login-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.account-choose-category-sec .category-list{
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.account-choose-category-sec .category-list ul{
    margin-bottom: 0;
}

.account-choose-category-sec .additional-info{
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.account-add-location-sec input{
    height: 2.8em;
        border: 1px solid #797878;
        border-radius: 50px;
}
.account-add-location-sec input:focus{
    box-shadow: none;
        border: 1px solid #797878;
}

.account-add-location-sec .input-group .input-group-text {
    background-color: transparent;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.invite-link-title h4{
    font-size: 1em;
    font-weight: 700;
    color: var(--teritary-color);
    margin-bottom: 0;
}

/* Responsive CSS */

@media (max-width: 991.98px){
    .account-box{
        grid-template-columns: 200px auto;
    }
}

@media (max-width: 767.98px){
.account-box{
    display: flex;
    flex-direction: column;
    gap: 0em;
}
.account-sidebar-list {
    padding: 1em 0;
    margin-bottom: 0;
}
.single-account-header-sec {
    padding: 1em;
    background-position: center;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}
.single-profile-link-sec{
    padding: 1em 1em;
}
.single-account-header-card{
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}
.single-profile-link-sec a{
    /* flex-direction: column;
    align-items: flex-end; */
    font-size: 0.9em;
}
.single-profile-link-tick-icon{
    height: 1.2em;
    object-fit: contain;
}
.single-account-dashboard-btn {
    position: absolute;
    bottom: 15px;
    width: 2.5em;
    height: 2.5em;
    right: 15px;
}
.single-account-dashboard-btn svg{
    width: 20px;
    height: 20px;
}
.single-profile-total-count-card:before{
    right: -15px;
}
.single-profile-total-count-card h5{
    font-size: 0.9em;
}
.single-profile-post-count-list{
    flex-wrap: wrap;
    gap: 1em;
}
.onboarding-header-sec{
    overflow-x: hidden;
}
.onboarding-header-card{
    left: 0!important;
}
}