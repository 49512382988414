.social-media-import-sec {
  margin-bottom: 4em;
}

.social-media-import-btn button {
  border: 1px solid var(--primary-color);
  padding: 8px 30px;
  font-size: 1em;
  color: var(--secondary-color);
  font-weight: 600;
  background: var(--primary-color);
  border-radius: 5px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  margin: 1.5em 0 0;
  text-align: center;
  cursor: pointer;
}

.social-media-import-btn button:hover,
.social-media-import-btn button:active,
.social-media-import-btn button:focus {
  background: var(--primary-color) !important;
  color: var(--secondary-color);
}

.social-media-import-icon-sec svg {
  width: 2em;
  height: 2em;
  fill: var(--secondary-color);
}

.social-media-import-box{
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 4em;
}

.social-media-import-card{
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2em;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.social-media-import-icon-sec{
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 1.2em;
}

.social-media-import-icon-sec.tiktok-bg{
  background-color: #ff0050;
}

.social-media-import-icon-sec.insta-bg{
  background-color: #F00073;
}

.social-media-import-icon-sec.twitter-bg{
  background-color: #1877F2;
}

.social-media-import-card h4{
  font-size: 1.1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom:0.6em;
}

.social-media-import-card p{
  color: #9896A1;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 500;
}

.social-media-import-card .social-media-import-info-sec .form-control{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  border-radius: 5px!important;
  padding:1em 1em!important;
}

.social-media-import-card .social-media-import-info-sec .form-control::placeholder{
  font-size: 0.9em;
}

.social-media-import-card .social-media-import-info-sec .form-control:focus{
  box-shadow: none!important;
}

.social-media-import-info-sec .form-group{
  margin-bottom: 0;
}

.social-media-import-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Social Media Select Post CSS */

.social-media-select-post-sec{
  margin-top: 48px;
  margin-bottom: 4em;
}

.social-media-select-post-card label {
  display: inline-block;
  background-color: transparent;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  color: #333333;
  border-radius: 0px;
  white-space: nowrap;
  margin: 0!important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  display: block;
}

.social-media-select-post-card input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.social-media-select-post-card label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  padding: 0;
  content: "\f111";
  color: rgba(0, 0, 0, 0.2);
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  position: absolute;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  right: 15px;
  transition: 0.3s;
  top: 10px;
  z-index: 2;
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  display: flex;
  align-items: center;
  border: 0;
  justify-content: center;
}

.social-media-select-post-card input[type="radio"]:checked + label::before {
  content: "\f058";
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  color: var(--primary-color);
  background: #fff;
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  display: flex;
  align-items: center;
  border: 0;
  justify-content: center;
}

.social-media-select-post-card{
  position: relative;
  cursor: pointer;
}

.social-media-select-post-card label::after{
  background: linear-gradient(227.73deg, rgba(0, 0, 0, 0.6) 2.68%, rgba(0, 0, 0, 0) 18.87%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.social-media-select-post-box{
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 0em; 
}

.social-media-select-post-img{
  height: 16em;
  object-fit: cover;
  border-radius: 10px;
  width:100%
}

.social-media-select-post-icon-sec{
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 1.2em;
  position: absolute;
  top: 10px;
  left: 10px;
}

.social-media-select-post-icon-sec svg{
  width: 1.5em;
  height: 1.5em;
  fill: var(--secondary-color);
}

.social-media-select-post-icon-sec.tiktok-bg{
  background-color: #ff0050;
}

.social-media-select-post-icon-sec.insta-bg{
  background-color: #F00073;
}

.social-media-select-post-icon-sec.twitter-bg{
  background-color: #1877F2;
}

.social-media-select-post-btn-sec button {
  border: 1px solid var(--primary-color)!important;
  padding: 8px 30px;
  font-size: 1.4em;
  color: var(--secondary-color);
  font-weight: 600;
  background: var(--primary-color);
  border-radius: 5px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  margin: 1.5em 0 0;
  text-align: center;
  cursor: pointer;
}

.social-media-select-post-btn-sec button:hover,
.social-media-select-post-btn-sec button:active,
.social-media-select-post-btn-sec button:focus {
  background: var(--primary-color) !important;
  color: var(--secondary-color);
  border-color: var(--primary-color)!important;
}

.social-media-select-post-btn-sec{
  display:flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1em;
}

.social-media-create-post-card{
  background: #F9F9F9;
  border-radius: 10px;
  padding: 2em 3em;
}

.social-media-create-post-card{
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.social-media-create-post-info h4{
  font-size: 1.1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom:0.6em;
}

.social-media-create-post-info p{
  color: #9896A1;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 500;
}

.social-media-create-post-btn-sec button {
  border: 1px solid var(--primary-color)!important;
  padding: 8px 30px;
  font-size: 1em;
  color: var(--secondary-color);
  font-weight: 600;
  background: var(--primary-color);
  border-radius: 5px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  margin: 1.5em 0 0;
  text-align: center;
  cursor: pointer;
}

.social-media-create-post-btn-sec button:hover,
.social-media-create-post-btn-sec button:active,
.social-media-create-post-btn-sec button:focus {
  background: var(--primary-color) !important;
  color: var(--secondary-color);
  border-color: var(--primary-color)!important;
}

.social-media-create-post-btn-sec a {
  border: 1px solid var(--primary-color)!important;
  padding: 8px 30px;
  font-size: 1em;
  color: var(--secondary-color);
  font-weight: 600;
  background: var(--primary-color);
  border-radius: 5px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  margin: 0.5em 0 0;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.social-media-create-post-btn-sec a:hover,
.social-media-create-post-btn-sec a:active,
.social-media-create-post-btn-sec a:focus {
  background: var(--primary-color) !important;
  color: var(--secondary-color) !important;
  border-color: var(--primary-color)!important;
}

.social-media-create-post-img{
  height: 10em;
  object-fit: contain;
}

.social-media-create-post-item{
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 2em;
}

/* Select Preview CSS */

.social-media-preview-post-img{
  height: 25em;
  object-fit: cover;
  border-radius: 10px;
  width:100%
}

.social-media-preview-post-icon-sec{
  width: 4em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 1.2em;
  position: absolute;
  top: 10px;
  left: 10px;
}

.social-media-preview-post-icon-sec svg{
  width: 2em;
  height: 2em;
  fill: var(--secondary-color);
}

.social-media-preview-post-icon-sec.tiktok-bg{
  background-color: #ff0050;
}

.social-media-preview-post-icon-sec.insta-bg{
  background-color: #F00073;
}

.social-media-preview-post-icon-sec.twitter-bg{
  background-color: #1877F2;
}

.social-media-preview-post-card::before{
  background: linear-gradient(227.73deg, rgba(0, 0, 0, 0.6) 2.68%, rgba(0, 0, 0, 0) 18.87%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.social-media-preview-post-card{
  position: relative;
}

/* Create New Item CSS */

.social-create-new-item-sec{
  margin-top: 48px;
  margin-bottom: 4em;
}

.social-create-new-item-header-sec h3{
  letter-spacing: 0.8px;
  font-weight: 700;
  font-size: 1.3em;
  padding-bottom: 1em;
}

.nft-preview-img-sec{
  height:100%;
  padding-bottom: 2em!important;
}

.nft-preview-img{
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.social-create-new-item-form label{
  font-weight: 700;
  font-size: 0.95em;
}

.social-create-new-item-form .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
}

.social-create-new-item-form .form-control::placeholder {
  color: var(--quinary-color);
  font-weight: 600;
  font-size: 0.85em;
}

.social-create-new-item-form .form-control:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.social-create-new-item-form p{
  font-size: 0.9em;
  color: var(--tertiary-color);
  font-weight: 400;
}

.royality-create-new-item{
  margin-top: 2em;
}

.properties-collapse-btn-sec svg{
  width:2em;
  fill: var(--primary-color);
}

.properties-collapse-btn-sec button{
  background-color: transparent!important;
  border-color: transparent!important;
  padding: 0!important;
}

.properties-collapse-info h4{
  font-size: 1.1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom:0.5em;
}

.properties-collapse-info p{
  color: #9896A1;
  margin-bottom: 0em;
  font-size: 1em;
  font-weight: 500;
}

.properties-collapse-sec{
  margin: 2em 0;
}

.properties-collapse-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F5F7FB!important;
  border-radius: 10px!important;
  padding: 2em;
}

.properties-collapse-btn svg{
  width:0.8em!important;
}

.add-properties-modal .social-create-new-item-form.edit-profile-form{
  padding-top: 0!important;
}

.add-properties-modal .modal-title{
  font-size: 1.2em;
  font-weight: 600;
}

.add-properties-modal .social-create-new-item-form.edit-profile-form .form-control{
  padding: 0 0.5em!important;
  border-radius: 0!important;
}

.add-properties-modal p{
  color: #9896A1;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 500;
}

.add-more-btn-sec button {
  background-color: var(--quaternary-color) !important;
  padding: 0.5em 1.5em !important;
  text-align: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50px !important;
  color: var(--secondary-color) !important;
  display: inline-block !important;
  border: 1px solid var(--quaternary-color) !important;
  font-size: 0.95em !important;
  letter-spacing: 0.8px;
  text-decoration: none !important;
 font-weight: 700 !important;
  text-transform: uppercase;
}

.add-more-btn-sec button:hover,
.add-more-btn-sec button:active,
.add-more-btn-sec button:focus {
  background-color: var(--quaternary-color) !important;
  color: var(--secondary-color) !important;
  box-shadow: none !important;
}

.save-btn-sec button {
  background-color: var(--quaternary-color) !important;
  padding: 0.5em 1.5em !important;
  text-align: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50px !important;
  color: var(--secondary-color) !important;
  display: inline-block !important;
  border: 1px solid var(--quaternary-color) !important;
  font-size: 0.95em !important;
  letter-spacing: 0.8px;
  text-decoration: none !important;
 font-weight: 700 !important;
  text-transform: uppercase;
}

.save-btn-sec button:hover,
.save-btn-sec button:active,
.save-btn-sec button:focus {
  background-color: var(--quaternary-color) !important;
  color: var(--secondary-color) !important;
  box-shadow: none !important;
}

.save-btn-sec{
  display:flex;
  align-items: center;
  justify-content: center;
}

.properties-item-box{
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 2em; 
}

.properties-item-card{
  border:1ps solid var(--primary-color);
  padding: 2em;
  background-color: #F5F7FB;
  border-radius: 10px;
  text-align: center;
}

.properties-item-card h4{
  font-size: 1.1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom:0em;
}

.properties-item-card p{
  color: var(--primary-color);
  margin-bottom: 0.5em;
  font-size: 1em;
  font-weight: 500;
}

.add-properties-modal ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.add-properties-modal ::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.add-properties-modal ::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}


.social-create-new-item-form.edit-profile-form .custom-select {
  background-color: var(--dark-blue)!important;
  color: var(--secondary-color)!important;
  font-size: 1.4em!important;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 50px!important;
}

.add-properties-form .form-lable{
  font-size: 1em;
  font-weight: 600;
}

.new-home-sec {
  padding: 48px;
  padding-bottom: 4em;
  padding-left: 4em;
  padding-right: 4em;
}

.new-home-box {
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  width: 100%;
}

.new-home-sidebar {
  width: 20%;
  /* padding: 1em; */
  /* position: sticky; */
  align-self: flex-start;
  /* top: 50px; */
  overflow-y: auto;
}

.new-home-main-wrapper {
  width: 80%;
  /* background-color: #f9fafc; */
  padding: 2em;
}

.profile-logo-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
}

.sibebar-header-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 2em;
}

.sidebar-user-img-sec {
  background-color: #000000;
  border: 4px solid #00c75e;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.sidebar-user-no-fea-img-sec{
  background-color: #000000;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@keyframes blink {
  50% {
      border-color: #fff;
  }
}

.sidebar-user-img {
  height: 7em;
  width: 7em;
  object-fit: cover;
}

.sibebar-header-sec h4 {
  font-size: 2em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 0;
  color: var(--tertiary-color);
  padding-top: 1em;
}

.dot-circle {
  width: 1.5em;
  height: 1.5em;
  background: #2bd618;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 2px;
}

.sidebar-live-btn {
  position: absolute;
  bottom: -16px;
  color: #fff;
  background: linear-gradient(90deg, #00c75e 4.55%, #AAA7F5 47.39%, #00c75e 92.42%);
  border: 3px solid #FFFFFF;
  font-size: 1.4em;
  padding: 2px 15px;
  font-weight: 600;
  border-radius: 5px;
}

.sidebar-user-name {
  color: #9896A1;
}

.sidebar-user-name:hover {
  color: #9896A1;
  font-size: 1.6em;
  font-weight: 500;
}

.sidebar-total-count-info-box {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  /* gap: 1rem; */
  place-items: center;
  text-align: center;
  width: 100%;
}

.sidebar-total-count-card {
  position: relative;
}

.sidebar-total-count-card::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  top: 0;
  right: -18px;
}

.sidebar-total-count-card:last-child::before {
  display: none;
}

.sidebar-total-count-card h5 {
  font-size: 1.3em;
  font-weight: 700;
}

.sidebar-total-count-card p {
  color: #9896A1;
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 500;
}

.sidebar-links {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2.5em 0;
}

.sidebar-links ul li a {
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 1.5em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.sidebar-links ul li {
  margin-top: 0;
  padding-bottom: 2em;
}

.sidebar-links ul li:last-child {
  padding-bottom: 0;
}

.sidebar-social-links {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2.5em 0;
}

.sidebar-social-links ul li {
  margin-top: 0;
}

.sidebar-social-links ul {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  place-items: center;
  gap: 1rem;
}

.user-cover-img-sec .user-cover-img {
  width: 100%;
  height: 28em;
  object-fit: cover;
  border-radius: 20px;
}

.user-info-list ul {
  margin-bottom: 0;
}

.user-info-list ul li {
  background: #F2F2F7;
  border-radius: 50px;
  padding: 8px 15px;
  margin-top: 0;
  display: inline-block;
  margin-right: 1em;
  margin-bottom: 1em;
}

.user-right-content-sec {
  grid-template-columns: 700px auto;
  display: grid;
  gap: 1rem;
}

/* .user-info-list ul {
  display: flex;
  align-items: center;
} */

.user-info-list ul li a {
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: default;
}

.user-info-list ul li a span {
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 600;
}

.user-right-info {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2em 0;
}

.user-info-desc {
  padding-bottom: 2em;
}

.user-info-desc p {
  color: #9896A1;
  margin-bottom: 0;
  font-size: 1.3em;
  font-weight: 400;
  padding-right: 1em;
}

.user-info-desc p a {
  color: var(--tertiary-color);
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 400;
}

.user-subscription-plans-details {
  padding: 2em 1em;
}

.user-subscription-plans-details h3 {
  font-size: 1.6em;
  color: var(--primary-color);
  font-weight: 600;
}

.user-subscription-btn-sec {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 0;
}

.subscription-outline-btn {
  border: 1px solid var(--dark-blue);
  padding: 12px 16px;
  font-size: 1.4em;
  color: var(--dark-blue);
  font-weight: 600;
  background: var(--secondary-color);
  border-radius: 50px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}

.subscription-outline-btn:hover {
  background: var(--dark-blue);
  color: var(--secondary-color) !important;
}

.subscription-btn {
  border: 1px solid var(--dark-blue);
  padding: 12px 16px;
  font-size: 1.4em;
  color: var(--secondary-color);
  font-weight: 600;
  text-transform: uppercase;
  background: var(--dark-blue);
  border-radius: 50px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.subscription-btn:hover {
  color: var(--secondary-color) !important;
}

.profile-tab-sec {
  padding: 2em 0;
}

.profile-tab-sec .nav {
  display: grid;
  gap: 0;
  border-bottom: 1px solid rgba(101, 97, 227, 0.2);
}

.grid-four-col {
  grid-template-columns: repeat(5, 1fr);
}

.grid-five-col {
  grid-template-columns: repeat(6, 1fr);
}

.profile-tab-sec .nav::before {
  display: none;
}

.profile-post-tab-icon {
  max-width: 1.5em;
  object-fit: contain;
}

.profile-tab-sec .nav-pills .nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 0;
}

.profile-tab-sec .nav-pills .nav-link {
  font-size: 1.4em;
  font-weight: 600;
  padding: 0.5em 0.5em;
  display: flex;
  align-items: center;
  gap: 1em;
  color: #9896A1;
}

.profile-all-post-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 3em;
}

/* Post Audio CSS*/

.profile-audio-img {
  height: 14em;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.profile-audio-post-card {
  position: relative;
}

.profile-audio-post-card::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 0.5em;
}

.profile-audio-icon-sec {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-audio-icon {
  max-width: 2.5em;
}

.profile-audio-post-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 3em;
}

/*Post Lock CSS*/

.profile-lock-img {
  height: 14em;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.profile-lock-post-card {
  position: relative;
}

.profile-lock-post-card::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 0.5em;
}

.profile-lock-icon-sec {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-lock-icon {
  max-width: 2.5em;
}

/*Profile Video CSS*/

.profile-video-img {
  height: 14em;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.profile-video-post-card {
  position: relative;
}

.profile-video-post-card::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 0.5em;
}

.profile-video-icon-sec {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
}

.profile-video-icon {
  max-width: 2.5em;
}

.profile-video-post-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 3em;
}

/* Post Image CSS*/

.profile-image-img {
  height: 14em;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.profile-image-post-card {
  position: relative;
}

.profile-img-post-box {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 3em;
}

.mobile-display {
  display: none;
}

.desktop-display {
  display: block;
}

.new-settings-sidebar-link-list {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .desktop-display {
      display: none;
  }
}

.profile-post-card-loader {
  height: 224px;
  border-radius: 10px !important;
}

@media (max-width: 767.98px) {
  .profile-post-card-loader {
      height: 176px !important;
  }
}

.new-profile-store-btn-sec select, .new-profile-store-btn-sec .dropdown button{
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-size: 14px;
  transition: all 150ms ease;
  width: 170px;
  height: 52px;
  box-sizing: border-box;
  background: linear-gradient(#f5f5ff,#fff);
  border: 0;
  border: 1px solid #00c75e;
  border-radius: 50px;
  padding: 10px 12px;
  color: #6c757d;
}

.new-profile-store-btn-sec select:focus, .new-profile-store-btn-sec .dropdown button:focus{
  outline: none;
}

.new-profile-store-header-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
}
.new-profile-store-btn-sec{
  grid-template-columns: 170px auto;
  display: grid;
  gap: 1rem;
}

.new-explore-search-card .new-explore-search-icon{
  width: 1.5em;
}

/*NFT Collection CSS*/

.nft-collection-box{
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 2em;
  gap: 2em;
  margin-top: 3em;
}

.nft-collection-card{
  position: relative;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.nft-collection-img-sec .nft-collection-img{
  height: 21em;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.nft-collection-icon{
  width: 2.5em;
  height: 2.5em;
  object-fit: contain;
}

.nft-collection-info{
  padding: 1em 0;
}

.nft-collection-info h4{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;    -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
  font-size: 1.4em;
  font-weight: 600;
  color: var(--tertiary-color);
  width:12em;
}

.nft-collection-info p{
  color: #9896A1;
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 500;
  /* word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;    
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
  width:12em;
}

.nft-collection-buy-now-btn{
  border: 1px solid var(--primary-color)!important;
  padding: 8px 20px;
  font-size: 1.2em;
  color: var(--secondary-color);
  font-weight: 500;
  background: var(--primary-color);
  border-radius: 5px;
  transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.nft-collection-buy-now-btn:hover {
  color: var(--secondary-color) !important;
}

.nft-collection-buy-now-btn:focus{
  color: var(--secondary-color) !important;
  border: 1px solid var(--primary-color)!important;
  outline: none!important;
}

.nft-collection-action-btn-sec{
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap:1em;
  align-items: center;
}

.new-profile-item-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
}

.new-profile-item-btn-sec {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

/* .custom-modal .modal-content .modal-header .modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
} */

#buyWithMoonPay, #sellWithMoonPay {
  background-color: #7d00fe;
  border: none;
  color: white;
  padding: 15px 50px 15px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: 0 auto;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  background-image: url("https://media.licdn.com/dms/image/C4D0BAQG1IQyuVONjqg/company-logo_200_200/0/1638558485699?e=1688601600&v=beta&t=vvO70JUB9zu9i3cjG74R1d4fRq8ypyf5EohHgD98aQ4");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}

.modal-overlay {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.on-ramp-modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #888;
  background-color: white;
  width: 90vw;
  height: 90vh;
  max-width: 475px;
  max-height: 650px;
  border-radius: 30px;
  overflow: hidden;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

#moonpayBuyIframe, #moonpaySellIframe {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
}

.margin-auto {
  margin: auto;
}

/* Other Profile CSS */

.other-profile-sec .single-profile-user-img-sec{
  border: 8px solid #E0F8EB;
  border-radius: 50%;
  position: relative;
}

.other-profile-user-tick-sec{
  position: absolute;
  top: 0;
  right: -15px;
}

.other-profile-user-name-card{
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 0.5em;
}

.other-profile-user-name-card h4{
  margin-bottom: 0;
}

.other-profile-user-name-copy-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  border: 1px solid #00C75E;
  background: rgba(0, 199, 94, 0.15);
  padding: 0.5em 1em;
  border-radius: 50px;
}

.other-profile-user-name-copy-card {
  display: flex;
  align-items: center;
  gap: 1em;
}

.other-profile-user-name-copy-card p{
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.other-profile-user-description-card{
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em;
}

.other-profile-user-description-card p{
  margin-bottom: 0;
  max-width: 350px;
  color: var(--quinary-color);
}

.other-profile-user-follow-reward-btn-sec .other-profile-user-follow-reward-btn {
  background-color: transparent!important;
  position: relative;
  border: 0!important;
  font-weight: 700;
  font-size: 1em;
  color: var(--tertiary-color)!important;
  display: flex;
  align-items: center;
  gap: 1em;
}

.other-profile-user-follow-reward-btn-sec .other-profile-user-follow-reward-btn:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgba(0,0,0,.1);
  top: 0;
  right: -20px;
}

.other-profile-user-follow-reward-btn-sec .other-profile-user-follow-reward-btn:last-child:before{
  display: none;
}

.other-profile-user-follow-reward-btn-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 1em;
}

.single-profile-social-media-followers-sec span{
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
}

.single-profile-social-media-followers-twitter{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
}

.single-profile-social-media-followers-facebook{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
}

.single-profile-social-media-followers-linkedin{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
}

.single-profile-social-media-followers-youtube{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
}

.single-profile-social-media-followers-sec.other-profile-social-media-followers-sec div{
  position: relative;
}

.single-profile-social-media-followers-sec.other-profile-social-media-followers-sec div::before{
  content: "";
  width: 0;
  height: 50%;
  position: absolute;
  border: 1px solid #EDEDED;
  top: 0;
  right: -20px;
}

.single-profile-social-media-followers-sec.other-profile-social-media-followers-sec div:last-child::before{
  display: none;
}

.single-profile-social-media-folllower-icon{
  width: 2em;
  height: 2em;
}

.single-profile-social-media-followers-sec.other-profile-social-media-followers-sec {
  gap: 2.5em;
}

.other-profile-box{
  padding: 2em 0;
}

.other-profile-points-info-sec h4{
  font-weight: 800;
  color: var(--tertiary-color);
  font-size: 1em;
  margin-bottom: 0.2em;
}

.other-profile-points-info-sec p{
  margin-bottom: 0;
  color:#0C0C0C;
  font-weight: 700;
 }

 .other-profile-points-box{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding-bottom: 3em;
  padding-top: 2em;
 }

 .other-profile-points-card{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
 }

 .other-profile-points-icon-sec{
  margin-bottom: 0.5em;
 }

 .other-profile-points-card::before{
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid #EDEDED;
  top: 0;
  right: -17px;
 }

 .other-profile-points-card:last-child:before{
  display: none;
 }

 .other-profile-nft-store-font-box{
    border: 1px solid #D9D9D9;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #45454512;
    border-radius: 24px;
    margin-top: 2em;
 }

 .other-profile-nft-store-font-header-sec{
    border-bottom: 1px solid #EDEDED;
    padding: 2em 2em;
 }

 .other-profile-nft-store-font-header-sec .nav-item{
  position: relative;
 }

 .other-profile-nft-store-font-header-sec .nav-link.active::before{
    background: linear-gradient(180deg, #5AC36A 0%, #08F578 100%);
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
 }

 .other-profile-nft-store-font-header-sec .nav-link{
  color: var(--tertiary-color)!important;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 0.5em;
 }

 .other-profile-nft-store-font-header-sec .nav-link.active{
  background-color: transparent!important;
  color: var(--tertiary-color)!important;
 }

 .other-profile-nft-store-font-header-sec .nav-pills{
  justify-content: center;
 }

 .other-profile-nft-store-font-body-sec{
    padding: 2em;
 }

 .other-profile-nft-store-font-card{
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.078);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
 }

 .other-profile-nft-store-font-info{
  padding: 1em;
 }

 .other-profile-nft-store-font-img{
  width: 100%;
  height: 27em;
  object-fit: fill;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
 }

 .other-profile-nft-store-font-info-top-sec h3{
  font-weight: 800;
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
 }

 .other-profile-nft-store-font-info-list li{
  display: flex;
  align-items: center;
  gap: 0.5em;
  position: relative;
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
 }

 .other-profile-nft-store-font-info-list{
  display: flex;
  align-items: center;
  gap: 1em;
 }

 .other-profile-nft-store-font-info-list li::before{
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid #EDEDED;
  top: 0;
  right: -9px;
 }

 .other-profile-nft-store-font-info-list li:last-child:before{
  display: none;
 }

 .other-profile-nft-store-font-info-top-sec p{
  margin-bottom: 0;
  font-size: 0.9em;
  color:#9BAFB6;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
 }

.other-profile-nft-store-font-info-top-sec{
  border-bottom: 0.3px solid #CCCBCB;
  padding-bottom: 1em;
} 

.other-profile-nft-store-font-info-bottom-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;
}

.other-profile-nft-store-font-info-bottom-sec .buy-token{
  background-color: #275878!important;
  padding: 0.8em 2em !important;
  text-align: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50px !important;
  color: var(--secondary-color) !important;
  display: inline-block !important;
  border: 1px solid #275878!important;
  font-size: 0.95em !important;
  letter-spacing: 0.8px;
  text-decoration: none !important;
  font-weight: 700!important;
}

.other-profile-nft-store-font-info-bottom-amount-sec span{
  font-size: 0.8em;
  color: #666666;
  font-weight: 500;
}

.other-profile-nft-store-font-info-bottom-amount-sec{
  font-size: 1.4em;
  font-weight: 800;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.other-profile-nft-store-font-info-bottom-amount-icon{
  width: 1.5em;
}

.other-profile-nft-store-font-good-deal-sec{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  font-size: 1em;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--primary-color);
  margin-top: 0.5em;
}

.other-profile-nft-store-font-latest-mint-pagination-left-sec h4{
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.other-profile-nft-store-font-latest-mint-pagination-list {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 0;
}

.other-profile-nft-store-font-latest-mint-pagination-list li a{
  width: 2.5em;
  height: 2.5em;
  background: rgba(0, 199, 94, 0.15);
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
  text-decoration: none!important;
  border-radius: 10px;
}

.other-profile-nft-store-font-latest-mint-pagination-list li a.active{
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.other-profile-nft-store-font-latest-mint-pagination-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2em;
}

/* Other profile Hashtags */

.other-profile-hashtags-box{
  border: 1px solid #D9D9D9;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #45454512;
  border-radius: 24px;
  margin-top: 2em;
  padding: 1.5em;
}

.other-profile-hashtags-header-title-sec{
  font-weight: 800;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  gap: 0.6em;
}

.other-profile-hashtags-more-btn-sec a{
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 0;
  text-decoration: none!important;
  display: flex;
  align-items: center;
  gap: 0.1em;
}

.other-profile-hashtags-header-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other-profile-hashtags-list-sec{
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}

.other-profile-hashtags-list-sec li a{
  border: 1px solid #00C75E;
  background-color: #F2FFF8;
  padding: 0.6em 2em;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  border-radius: 50px;
  text-decoration: none!important;
}

.other-profile-hashtags-body-sec{
  margin-top: 1.5em;
}

.other-profile-hashtags-header-title-icon{
  width: 2em;
  height: 2em;
  object-fit: cover;
  border-radius: 50%;
}

.other-profile-mentions-list-sec{
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}

.other-profile-mentions-list-sec li a{
  border: 1px solid #00C75E;
  background-color: #F2FFF8;
  padding: 0.6em 1.5em;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  gap: 1em;
  border-radius: 50px;
  text-decoration: none!important;
}

.other-profile-mentions-body-sec{
  margin-top: 1.5em;
}

.other-profile-mentions-icon{
  width: 2.5em;
  height: 2.5em;
  object-fit: cover;
  border-radius: 50%;
}

.border-right-line-theme{
  border-right: 1px solid #D9D9D9;
}

.other-profile-nft-store-font-header-sec.other-profile-review-testimonials-header-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
}

.border-right-line-theme a{
  padding-left: 0;
}

.other-profile-sponsored-reviews-company-icon{
  width: 2em;
  height: 2em;
  object-fit: contain;
}

.other-profile-sponsored-reviews-company-info{
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 700;
  font-size: 1em;
  color: var(--tertiary-color);
}

.other-profile-sponsored-reviews-header-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other-profile-sponsored-review-rating-star-list {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.other-profile-sponsored-reviews-card{
  padding: 1.5em;
  border-bottom: 1px solid #EDEDED;
}

.other-profile-sponsored-reviews-card:last-child{
  border-bottom: 0;
}

.other-profile-sponsored-reviews-body-sec{
  padding-top: 1em;
  color: var(--quinary-color);
}

/* Other profile link tree css */

.other-profile-link-tree-box{
  border: 1px solid #D9D9D9;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #45454512;
  border-radius: 24px;
  padding: 1.5em;
}

.other-profile-link-tree-body-sec{
  padding-top: 1.5em;
}

.other-profile-link-tree-btn{
  border: 1px solid #D9D9D9;
  width: 85%;
  padding: 0.8em 1.5em;
  border-radius: 50px;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color)!important;
  text-decoration: none!important;
  display:flex;
  align-items: center;
  gap: 0.5em;
  position: relative;
}

.other-profile-link-tree-btn::before {
  content: "";
  width: 0;
  height: 50%;
  position: absolute;
  border: 1px solid #EDEDED;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -30px;
}

.other-profile-link-tree-btn:hover{
  color: var(--tertiary-color)!important;
}

.other-profile-link-tree-btn span{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.other-profile-link-tree-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.other-profile-link-tree-card:last-child{
  margin-bottom: 0;
}

.other-profile-link-tree-important-count{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  font-weight: 700;
  font-size: 1.1em;
}

.other-profile-link-tree-link-important-icon{
  height: 1.8em;
  object-fit: contain;
  width: 1.8em;
}

/* Other Profile Post Card CSS */

.other-profile-post-card-box{
  border: 1px solid #D9D9D9;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #45454512;
  border-radius: 24px;
  padding: 1.5em;
  margin-top: 2em;
}

.other-profile-post-card-tab-nav{
  display: flex;
  align-items: center;
}

.other-profile-post-card-tab-nav .nav-pills{
  margin: auto;
}

.other-profile-post-card-tab-nav-icon{
  width: 2em;
}

.other-profile-post-card-tab-nav .nav-pills {
  border-bottom: 4px solid #E4F7EC;
}

.other-profile-post-card-tab-nav .nav-pills .nav-link.active{
  border-bottom: 4px solid var(--primary-color);
  background-color: transparent;
  color: var(--tertiary-color);
  border-radius: 4px;
}

.other-profile-post-card-tab-content-list{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.other-profile-post-card-tab-content-list li a{
  padding: 0.6em 2em;
  background-color: transparent;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-decoration: none!important;
  border: 1px solid #D9D9D9;
}

.other-profile-post-card-tab-content-list{
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  margin-top: 1em;
}

.other-profile-post-card-tab-content-list li a.active{
  border: 1px solid #00C75E;
  background-color: #F2FFF8;
}

.other-profile-post-img-sec{
  position: relative;
}

.other-profile-post-card.other-profile-post-buy-card .other-profile-post-img-sec::before{
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.other-profile-post-buy-btn-sec{
  position: absolute;
  bottom: 50px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* right: 0;
  transform: translateY(-50%); */
}

.other-profile-post-buy-btn{
  background-color: var(--primary-color) !important;
  padding: 0.8em 5em!important;
  width: unset;
  text-align: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50px !important;
  color: var(--secondary-color) !important;
  display: inline-block !important;
  border: 1px solid var(--primary-color) !important;
  font-size: 0.95em !important;
  letter-spacing: 0.8px;
  text-decoration: none !important;
  font-weight: 700!important;
}

.other-profile-post-img{
  width: 100%;
  /* height: calc(100vh - 200px); */
  height: 550px;
  object-fit: cover;
  /* box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.078); */
}

.other-profile-post-like-count-card{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.other-profile-post-like-user-1 {
  border-radius: 50%;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  width: 30px;
  height: 30px;
  position: relative;
  left: 3px;
  object-fit: cover;
  object-position: top;
}

.other-profile-post-like-user-2 {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  object-fit: cover;
  object-position: top;
  left: -6px;
  position: relative;

}

.other-profile-post-like-user-3 {
  border-radius: 50%;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  object-fit: cover;
  object-position: top;
  width: 30px;
  height: 30px;
  position: relative;
  right: 15px;
}

.other-profile-post-like-count-sec span{
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
  position: relative;
  right: 8px;
}

.other-profile-post-like-count-sec{
  display: flex;
  align-items: center;
}

.other-profile-post-count-sec{
  margin-top: 1em;
}

.other-profile-post-share-user-1 {
  border-radius: 50%;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  width: 30px;
  height: 30px;
  position: relative;
  left: 3px;
  object-fit: cover;
  object-position: top;
}

.other-profile-post-share-user-2 {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  object-fit: cover;
  object-position: top;
  left: -6px;
  position: relative;

}

.other-profile-post-share-user-3 {
  border-radius: 50%;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  object-fit: cover;
  object-position: top;
  width: 30px;
  height: 30px;
  position: relative;
  right: 15px;
}

.other-profile-post-share-count-sec span{
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
  position: relative;
  right: 8px;
}

.other-profile-post-share-count-card{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.other-profile-post-count-left-sec{
  display: flex;
  align-items: center;
  gap:0.5em;
}

.other-profile-post-share-count-sec{
  display: flex;
  align-items: center;
}

.other-profile-post-available-status-sec{
  display: flex;
  align-items: center;
  gap: 0.5em;
  position: relative;
}

.other-profile-post-available-status-sec::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid #EDEDED;
  top: 0;
  right: -12px;
}

.other-profile-post-available-status-sec span{
  font-size: 1em;
  font-weight: 700;
  color: var(--primary-color);
}

.other-profile-post-count-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other-profile-post-available-status-icon{
  height: 1.2em;
  object-fit: contain;
  width: 1.2em;
}

.other-profile-post-available-points-sec{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
}

.other-profile-post-available-points-sec span{
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color);
}

.other-profile-post-available-points-icon {
  height: 1.8em;
  object-fit: contain;
  width: 1.8em;
}

.other-profile-post-available-points-card{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5em;
}

.other-profile-post-footer-btn-sec{
  display: flex;
  align-items: center;
  gap: 1em;
}

.other-profile-post-footer-btn{
  background-color: transparent!important;
  border: 0!important;
  padding: 0!important;
}

.other-profile-post-footer-btn-icon{
  height: 2.5em;
  object-fit: contain;
  width: 2.5em;
}

.other-profile-post-footer-btn-sec{
  margin-top: 1em;
}

.other-profile-post-box{
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 1.5em;
}

.single-profile-preimum-title-sec{
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.2em 1em;
  border-radius: 50px;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--primary-color);
  bottom: -16px;
  background: #d3ffe8;
}

.profile-desc p{
  margin-bottom: 0;
  max-width: 350px;
  color: var(--quinary-color);
  text-align: center;
}

.other-profile-user-follow-reward-btn-sec h4{
  font-size: 1em;
  font-weight: 700;
  color: var(--tertiary-color)!important;
}

.other-profile-user-follow-reward-btn-sec h4 span{
  font-weight: 400;
}

.profile-link-pin-btn{
  background-color: transparent!important;
  border: 0!important;
  padding: 0!important;
}

.profile-link-pin-btn-sec{
  margin-left: auto;
}

.profile-link-tree-sec .other-profile-link-tree-btn{
  width: 88%;
}

.other-profile-link-tree-close-btn{
  border: 1px solid #D9D9D9;
  background-color: rgb(150 176 183 / 13%);
  width: 88%;
  padding: 0.8em 1.5em;
  border-radius: 50px;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color)!important;
  text-decoration: none!important;
  display:flex;
  align-items: center;
  gap: 0.5em;
  position: relative;
}

.other-profile-link-tree-close-btn::before {
  content: "";
  width: 0;
  height: 50%;
  position: absolute;
  border: 1px solid #EDEDED;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -30px;
}

.other-profile-link-tree-close-btn:hover{
  color: var(--tertiary-color)!important;
}

.all-points-sold-badge{
  background: linear-gradient(180deg, #5AC36A 0%, #08F578 100%)!important;
  padding: 0.8em 2em!important;
  width: unset;
  text-align: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50px !important;
  color: var(--secondary-color) !important;
  display: inline-block !important;
  border: 1px solid var(--primary-color) !important;
  font-size: 0.95em !important;
  letter-spacing: 0.8px;
  text-decoration: none !important;
  font-weight: 700!important;
}

.other-profile-link-tree-link {
  width: 85%;
}

.other-profile-link-tree-link .other-profile-link-tree {
  height: 50px;
  border-radius: 24px;
}
/* Add Link Sec CSS */

.add-link-box{
  padding: 2em 0;
}

.add-link-item{
  border: 1px solid #D9D9D9;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #45454512;
  border-radius: 24px;
  margin-top: 2em;
}

.add-link-user-img-icon{
  border: 1px solid #5AC36A;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
}

.add-link-left-sec{
  display: flex;
  align-items: center;
  gap: 1em;
}

.add-link-header-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 1em;
  padding: 1.5em;
}

.add-new-link-btn{
  border: 1px solid var(--primary-color)!important;
  background-color: transparent!important;
  color: var(--tertiary-color)!important;
  border-radius: 50px!important;
  padding: 0.5em 1.5em!important;
  font-weight: 700!important;
  display: flex!important;
  align-items: center;
  gap: 0.5em;
}

.add-new-link-attach-icon{
  height: 1.4em;
  width: 1.4em;
  object-fit: contain;
}

.add-link-body-sec{
  padding: 1.5em;
}

.add-link-tab-sec .nav-pills {
  display: flex;
  align-items: center;

  justify-content: center;
  padding: 0;
  margin: 0;
}

.add-link-tab-sec .nav-pills .nav-item {
  list-style: none;
}

.add-link-tab-sec .nav-pills .nav-link {
  padding: 0.5em 3em;
  text-align: left;
  text-decoration: none;
  color: #96B0B7;
  border-bottom: 5px solid #E0F8EB;
  border-radius: 0;
  font-size: 1.1em;
  /* font-weight: 700; */
  font-weight: 700;
}

.add-link-tab-sec a:hover {
  color: #0C0C0C;
}
.add-link-tab-sec{
  margin: 0 0 3em;
}

.add-link-tab-sec .nav-item .nav-link.active {
  background: transparent;
  border: none;
  color: var(--tertiary-color);
  border-bottom: 5px solid var(--quaternary-color);
  border-radius: 0;
}

.add-link-card-sec{
  padding: 2em 0;
}

.add-link-card{
  border: 0.8px solid #D9D9D9;
  border-radius: 24px;
}

.add-link-card-left-sec{
  padding: 1.5em 2em;
  grid-template-columns: 150px auto;
  display: grid;
  gap: 1.5em;
  align-items: center;
  flex: 1;
}

.add-link-card-left-static-icon-sec{
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  place-items: center;
  align-items: center;
  gap: 1em;
}

.add-link-card-static-logo-edit-icon-sec{
  position: relative;
}

.add-link-card-static-logo-edit-options-sec input[type="file"] {
  display: none;
}

.add-link-card-static-logo-edit-options-sec{
  position: absolute;
  top: 0;
  top: 56%;
  left: 60%;
  border-radius: 50%;
  z-index: 99;
}

.add-link-form-box .add-link-form-card .form-control{
  border-top: 0;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0;
  color: var(--tertiary-color);
  font-weight: 700;
  border-color: #D9D9D9!important;
}

.add-link-form-box .add-link-form-card .input-group-text{
  background-color: transparent!important;
  border-right: 0!important;
  border-top: 0!important;
  border-radius: 0!important;
  border-bottom: 0!important;
}

.add-link-form-box .add-link-form-card .form-control:focus{
  box-shadow: none;
  border-color: #D9D9D9!important;
}

.add-link-form-box{
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 0.5em;
}

.add-link-card{
  display: flex;
  justify-content: space-between;
}

.add-link-view-btn{
  background-color: transparent!important;
  border: 0!important;
  padding: 0!important;
}

.add-link-remove-btn{
  background-color: transparent!important;
  border: 0!important;
  padding: 0!important;
}

.add-link-card-right-sec {
  border: #D9D9D9 1px solid;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-link-card-right-sec hr {
  border-width: 1px 0 0 0;
  border-color: #D9D9D9;
  opacity: 1;
  margin: 0;
}

.add-link-card-right-btn-sec {
  width: 3em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-the-link-modal-yes-btn{
  background-color: transparent!important;
  border: 0!important;
  color: #FF006C!important;
  font-weight: 700!important;
  font-size: 0.9em!important;
  padding-bottom: 0!important;
}

.delete-the-link-modal-cancel-btn{
  background-color: transparent!important;
  border: 0!important;
  color: var(--tertiary-color)!important;
  font-weight: 700!important;
  font-size: 0.9em!important;
  padding-top: 0!important;
}

.delete-the-link-modal-top-btn-sec{
  border-bottom: 0.6px solid #D9D9D9;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.delete-the-link-modal-bottom-btn-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
}

.delete-thelink-modal h2{
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
  color: var(--tertiary-color);
  margin-top: 1.5em;
}

.delete-thelink-modal .modal-body{
  padding: 0;
}

.add-link-card-item{
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 1em;
}

.add-link-form-card:last-child .form-control::placeholder{
  color:#4399C3;
}

.other-profile-user-name-copy-logo {
  cursor: pointer;
}

.add-link-card-left-static-link-logo-edit-icon, .other-profile-link-tree-link-icon {
  width: 50px;
  height: 50px;
}

.link-loader {
  width: 30px;
  height: 30px;
}
.page-loader-card{
  border: 1px solid #D9D9D9;
  background: var(--secondary-color);
  box-shadow: 0px 3px 6px #45454512;
  border-radius: 24px;
  padding: 1.5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-loader-sec{
  padding: 2em 0;
}

.page-loader-img{
 width: 80%;
}

.page-loader-box{
  display: flex;
  justify-content: center;
  height: 90vh;
}

/* .page-loader-card svg {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 150px;
	width: 150px;
} */

.spinner-3 {
  width: 200px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg ,rgb(0 199 94/50%) 30%,#fff 0 70%,rgb(0 199 94/100%) 0) 50%/8% 100%,
    linear-gradient(90deg,rgb(0 199 94/25%) 30%,#fff 0 70%,rgb(0 199 94/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);
}
.spinner-3::before,
.spinner-3::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.spinner-3::after {
   opacity: 0.83;
   transform: rotate(60deg);
}

@keyframes s3 {
  100% {transform: rotate(1turn)}
}
