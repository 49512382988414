.auth-sec{
    background-color: #FAFAFA;
    min-height: 100vh;
    display: flex;
}

.authentication-sec{
    background-color: #FAFAFA;
    min-height: 100vh;
}

.account-completion-sec{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #45454512;
    border-radius:12px;
    opacity: 1;
    padding: 2em;
}

.auth-left-sec{
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
}

.auth-right-sec{
    width: 60%;
    margin-left: 36em;
    padding-top: 4em;
}

.auth-left-img{
    width: 40%;
    height: 100vh;
    object-fit: cover;
}

.auth-right-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #45454512;
    border-radius: 0px 20px 20px 0;
    opacity: 1;
    padding: 2em;
}

.auth-right-box{
    max-width: 600px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.login-nav-link ul {
    display: flex;
    align-items: center;

    justify-content: center;
    padding: 0;
    margin: 0;
}

.login-nav-link ul li {
    list-style: none;
}

.login-nav-link a {
    padding: 0.5em 3em;
    text-decoration: none;
    color: #96B0B7;
    border-bottom: 5px solid #E0F8EB;
    border-radius: 0;
    font-size: 1.1em;
    /* font-weight: 700; */
    font-weight: 700;
}

.login-nav-link a:hover {
    color: #0C0C0C;
}
.login-nav-link{
    margin: 0 0 3em;
}

.login-nav-link li.active a {
    background: transparent;
    border: none;
    color: var(--tertiary-color);
    border-bottom: 5px solid var(--quaternary-color);
    border-radius: 0;
}

.auth-logo-img-sec{
    text-align: center;
    margin-bottom: 1em;
}

.auth-footer-link-list{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 0;
}

.auth-footer-link-list li a{
    color: #454545;
    text-decoration: none!important;
}

.sign-up-link h4 {
    font-size: 1em;
    text-align: center;
    margin: 1em 0 0;
}

.sign-up-link h4 a {
    padding-left: 0.5em;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 700;
    font-size: 0.95em;
}

.sign-in-sec .input-group input {
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

}

.sign-in-sec .input-group input::placeholder {
    font-size: 0.9em;
    vertical-align: middle;
}

.sign-in-sec .input-group input:focus {
    box-shadow: none;
}

.sign-in-sec .input-group .input-group-text {
    border: none;
    background-color: transparent;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

}

.sign-in-sec .input-group .input-group-text img {
    max-width: 1.3em;
}

.sign-in-sec .input-group {
    height: 2.8em;
    border: 1px solid #797878;
    /* width: 25em; */
    border-radius: 50px;
}

.border-left {
    border-left: 1px solid #9BAFB6;

}

.form-check-input:checked {
    background: #EDEDED !important;
    border-color: #EDEDED !important;
}

.form-check-input:checked[type=checkbox] {
    background-image: url('../../assets/tick.svg') !important;
}

.form-check-input {
    background-color: #EDEDED !important;
    border-color: #000;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.form-check {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.auth-slider .slick-slider .slick-arrow {
    display: none !important;
}

.sign-in-sec label {
    font-size: 0.9em;
    color: var(--tertiary-color);
    font-weight: 700;
}

.Forgot-password-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Forgot-password-link a {
    font-size: 0.9em;
    text-decoration: none;
    color: #4399C3;
    font-weight: 700;
    margin: 0 0 0.5em;
}

.Forgot-password-link a:hover {
    color: #4399C3;
}

.forgot-password-content h4 {
    font-size: 1.2em;
    border-bottom: 3px solid var(--primary-color);
    padding: 0 0 0.5em;
    /* max-width: 240px; */
    font-weight: 700;
    display: inline-block;
}

.forgot-password-content p {
    font-size: 0.9em;
    color: #454545;
    font-weight: 600;
}

.forgot-password-content {
    max-width: 400px;
}

.forgot-password-bottom-link {
    display: flex;
    align-items: center;

    justify-content: center;
    margin: 1em 0 0;
}

.forgot-password-bottom-link a {
    font-size: 0.9em;
    text-decoration: none;
    color: #4399C3;
    font-weight: 700;

}

.forgot-password-bottom-link a:hover {
    color: #4399C3;
}

.forgot-password-bottom-link a:first-child {
    border-right: 1px solid #4399C3;
    padding-right: 1em;
}

.forgot-password-bottom-link a:last-child {
    padding-left: 1em;
}

.separtor-or-sec{
    color: var(--quinary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    border-top: 1px solid var(--quinary-color);
    position: relative;
}

.separtor-or-sec span{
    position: absolute;
    background-color: var(--secondary-color);
    top: -22px;
    padding: 0.5em 1em;
}

.auth-social-link-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 2em;
}

.static-link-card{
    display: flex;
    align-items: center;
    gap: 1em;
}

.static-link-card a{
    color:var(--primary-color);
}

.phone-number-verification h2{
    font-size: 1.15em;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--tertiary-color);
    text-align: center;
    margin: 1em 0 1em;
}

.phone-number-verification h4{
    font-size: 0.95em;
        font-weight: 700;
        text-transform: capitalize;
        color: var(--tertiary-color);
        text-align: center;
        margin: 1em 0 1em;
}

.phone-number-verification p{
    font-size: 1em;
    text-align: center;
        color: var(--quinary-color);
        font-weight: 600;

}
.phone-number-verification-sec .verification-btn-sec{
    /* flex-direction: column; */
        gap: 1em;
}
.resend-link{
    text-decoration: none;
    color: var(--quaternary-color);
    font-size: 1em;
  font-weight: 700;
}

.verification-item {
    margin: 1.5em 0;
}
.verification-logo-sec img{
    max-width: 100%;
}

.verification-item input {
    width: 3rem !important;
    height: 3rem;
    font-size: 1.5rem;
    border-radius: 12px;
    border: 0;
    background-color: #EDEDED;
}

.verification-item input:focus-visible{
    background-color: #E0F8EB;
    border-color: var(--primary-color)!important;
    outline-color:var(--primary-color)!important;
}

.verification-btn-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5em;
}

.phone-number-verification-sec .verify-btn-sec{
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone-number-verification-sec .verify-btn-sec .default-btn{
    width: auto!important;
    padding: 0.5em 2em!important;
}

.verification-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}



.verification-logo-sec {
    text-align: center;
}
.default-outline-btn{
    background-color: transparent !important;
        padding: 0.5em 1.5em !important;
        text-align: center;
        transition: all 0.5s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 50px !important;
        color: var(--quaternary-color) !important;
        display: inline-block !important;
        border: 1px solid var(--quaternary-color) !important;
        font-size: 0.95em !important;
        letter-spacing: 0.8px;
        text-decoration: none !important;
        font-weight: 700 !important;
        text-transform: uppercase;
}
.default-btn-outline:hover,
.default-btn-outline:focus,
.default-btn-outline:active {
        background-color: var(--secondary-color) !important;
    color: var(--quaternary-color) !important;
    box-shadow: none !important;
}
.verification-item div{
    justify-content: center;
    gap: 1em;
}

.verification-box {
    background:var(--secondary-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2em;
}

.verification-box h4 {
    font-size: 1.3em;
    font-weight: 700;
    margin-bottom: 0.5em;
    color: var(--teritary-color);
    line-height: 1.5;
    text-align: center;
}


.modal-close {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
    position: absolute;
    right: 15px;
    top: 15px;
}

.modal-close .close-icon {
    width: 1em;
}

.modal-close:focus {
    box-shadow: none !important;
    outline: none !important;
}

.login-nav-link.forgot-password-nav-link ul{
    justify-content: flex-start
}

.login-nav-link.forgot-password-nav-link a{
    padding-left: 0;
    padding-right: 0;
}

.login-nav-link.forgot-password-nav-link{
    margin-bottom: 1.5em;
}

.notes-auth{
    color: var(--quinary-color);
    font-size: 0.9em;
}

.user-auth-link-sec .user-auth-link{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    border-top: 1px solid #D9D9D9;
    margin-bottom: 0;
    padding-top: 1em;
    margin-top: 1em;
}

.user-auth-link li{
    border-right: 1px solid #D9D9D9;
    padding-right: 1em;
}

.user-auth-link li:last-child{
    border-right: 0;
    padding-right: 0;
}

.user-auth-link li a{
    color: var(--quinary-color);
    font-size: 0.9em;
    text-decoration: none!important;
}

.reset-btn-sec {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-btn-sec .default-btn{
    width: auto;
    padding: 0.5em 3em!important;
}

.password-success-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2em;
}

.password-success-card h2{
    font-size: 1.15em;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--tertiary-color);
    text-align: center;
    margin: 1em 0 1em;
}

.password-success-card h4{
    font-size: 0.95em;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--tertiary-color);
    text-align: center;
    margin: 1em 0 1em;
}

.password-success-card p{
    font-size: 1em;
    text-align: center;
    color: var(--quinary-color);
    font-weight: 600;

}

.password-success-tick-icon{
    width: 4em;
}

.ok-btn{
    color: var(--teritary-color);
    text-align: center;
    text-decoration: none!important;
}

.view-privte-key-sec h3{
    font-size: 1.5em;
    color: var(--primary-color);
    font-weight: 700;
    text-align: center;
}
.view-privte-key-sec h4{
    font-size: 1.5em;
    color: var(--teritiary-color);
    text-align: center;
    font-weight: 700;
    margin: 0 0 0.5em;
}
.view-privte-key-sec h5{
    font-size: 1.15em;
    color: var(--teritiary-color);
    text-align: center;
    font-weight: 600;
    margin: 0 0 2em;
}
.view-privte-key-sec p span{
    font-weight: 700;
}

.private-key-sec h4 {
    max-width: 270px;
    margin: 0 0 0.7em;
    font-size: 1.1em;
}

.private-key-sec h3 {
    font-size: 1.2em;
    font-weight: 700;
    color: var(--tertiary-color);
    margin: 0 0 0.8em;
}

.private-key-sec p span {
    font-size: 1em;
    color: var(--tertiary-color);
    font-weight: 700;
    margin: 0 0 0.7em;
}

.private-key-content h5 {
    font-size: 1em;
    color: var(--tertiary-color);
    font-weight: 700;
    margin: 0 0 0.7em;
}

.private-key-box {
    background-color: rgba(150, 176, 183, 0.1);
    padding: 1em;
    margin: 0 0 1em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.private-key-box p {
    font-size: 0.9em;
    color: var(--tertiary-color);
    font-weight: 600;
    word-break: break-all;
    margin-bottom: 0;
}

.private-key-box .private-key-btn {
    text-align: end;
}

.private-key-box .private-key-btn button {
    background-color: transparent;
    border: none;
}

.private-key-box .private-key-btn button:hover {
    background-color: transparent;
}

.private-key-box .private-key-btn button span {
    color: #4399C3;
    font-size: 0.9em;
    font-weight: 600;
}

.private-key-btn button img {
    max-width: 1.3em;
    padding-right: 0.3em;
}

.social-button {
    border: none;
    background: transparent;
}

.private-key-warning span {
    color: #f0310c;
    font-size: 0.9em;
}

.private-key-warning{
   color: var(--quinary-color);
}

.onboarding-section-title h2{
    padding: 0.5em 0em;
    text-decoration: none;
    border-radius: 0;
    font-size: 1.1em;
    font-weight: 700;
    background: transparent;
    color: var(--tertiary-color);
    border-bottom: 5px solid var(--quaternary-color);
    border-radius: 0;
    display: inline-block;
    margin-bottom: 1em;
}

.autentication-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.account-completion-img-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}

.account-completion-img{
    max-width: 12em;
}

.view-privte-key-sec h4{
    display:flex;
    align-items: center;
    gap: 1em;
    justify-content: center;
}

.account-completion-sec .auth-logo-img-sec{
    margin-bottom: 0;
}

.account-completion-sec .view-privte-key-sec h5{
    font-size:1em;
    line-height: 1.8;
    max-width: 350px;
    margin: auto;
    margin-bottom: 1em;
}

.account-completion-sec .view-privte-key-sec h3{
    margin-bottom: 1em;
}

.link-your-account-sec input {
    width: 85%;
    outline: none;
}

.auth-social-link-sec li {
    cursor: pointer;
}

.location-sec {
    flex: 1;
}